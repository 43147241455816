<template>
  <div class="dataM workCalendar">
    <div class="content">
      <div class="title">
        <div>日历方案列表</div>
        <div>
          <el-tooltip v-premiSub="'工作日历_刷新'" effect="dark" content="刷新" placement="top">
            <img style="margin-right:10px" src="@/assets/images/dataManage/refresh.png" @click="getWorkList(1)"/>
          </el-tooltip>
          <router-link to="editworkCalendar" v-premiSub="'工作日历_新建'">
            <el-tooltip effect="dark" content="新增" placement="top">
              <img src="@/assets/images/dataManage/add.png"/>
            </el-tooltip>
          </router-link>
        </div>
      </div>
        <el-table :data="tableData" header-cell-class-name="tableHeader" style="width: 100%" @row-dblclick="edit">
          <el-table-column label="序号" type="index" width="100">
            <template slot-scope="scope">
              <span>{{((page-1)*size+scope.$index+1)}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="workshiftSchemeName" label="日历方案名称" width="350">
            <template slot-scope="scope">
              <span>{{scope.row.workshiftSchemeName}}</span>
              <span v-if="scope.row.isDefault" class="isDefault">默认</span>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="更新时间" width="350"/>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-tooltip v-premiSub="'工作日历_编辑'" effect="dark" content="编辑" placement="top">
                <img src="@/assets/images/dataManage/edit.png" style="margin-right:15px" @click="edit(scope.row)"/>
              </el-tooltip>
              <el-tooltip v-premiSub="'工作日历_删除'" effect="dark" content="删除" placement="top">
                <img src="@/assets/images/dataManage/del.png"  @click="comfirm(scope.row)"/>
              </el-tooltip>
            </template>
          </el-table-column>
      </el-table>
      <pagination :total="total" :page.sync="page" :size.sync="size"  @getTableData="getWorkList"/>
    </div>
  </div>
</template>

<script>
const pagination = () => import('@/components/pagination')
import {getWorkshift,delWorkshift} from '@/api/data.js'
import { isComfirm } from '@/utils/data.js'
export default {
  name: "workCalendar",
  components:{
    pagination
  },
  data() {
    return {
      tableData:[],
      total:0,
      page:1,
      size:10
    };
  },
  watch:{
    size(){
      this.getWorkList(1)
    }
  },
  activated(){
    this.getWorkList()
  },
  methods:{
    getWorkList(page){
      page ? this.page = page : this.page = 1;
      let params = {
        page:this.page,
        size:this.size
      }
      getWorkshift(params).then(res=>{
        if(res.code === 0){
          this.tableData = res.data.records
          this.total = res.data.total
        }else{
          this.$message.error(res.msg || '接口出错')
        }
      }).catch(()=>{
        this.$message.error('接口出错')
      })
    },
    edit(row){
      this.$router.push({
        path:'editworkCalendar',
        query:{
          id:row.id,
          shiftId:row.shiftId
        }
      })
    },
    comfirm(row){
      isComfirm(row,this.del)
    },
    del(row){
      delWorkshift(row.shiftId).then(res=>{
        if(res.code === 0){
          this.$message.success('删除成功')
          this.getWorkList()
        }else{
          this.$message.error(res.msg || '接口失败')
        }
      })
    }
  }
};
</script>

<style lang="scss">
.workCalendar{
  padding: 18px;
  color: #E8E8E8;
  .content{
    position: relative;
    background: #232323;
    min-height: calc(100vh - 280px);
    text-align: left;
    padding: 25px 35px 80px 35px;
    .tableHeader{
      background: #2C2C2C;
      color: #B9B9B9;
    }
    .title{
      font-size: 20px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
    }
    img{
      width: 22px;
      height: 22px;
      cursor: pointer;
    }
    .isDefault{
      color: #2DEB79;
      margin-left: 10px;
    }
  }
}
</style>
